import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['source']
  static values = { textBeforeClick: String, textAfterClick: String }

  copy(event) {
    event.preventDefault()
    event.stopPropagation()

    event.target.innerHTML = this.textAfterClickValue
    setTimeout(function () {
      event.target.innerHTML = this.textBeforeClickValue
    }.bind(this), 1000)
    const el = document.createElement('textarea')
    el.value = this.sourceTarget.innerText
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
  }
}
