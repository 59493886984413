import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  connect() {
    const dialog = document.querySelector("div[display='on']")
    if (dialog) {
      dialog.hidden = false
    }
  }

  show({ params: { id } }) {
    document.getElementById(id).hidden = false
  }

  close({ params: { id } }) {
    this.dispatch('close', { detail: { id: id } })
    document.getElementById(id).hidden = true
  }

  // toggleableElements : 'element-to-show#element-to-hide1|element-to-hide2'
  toggle({ params: { toggleableElements } }) {
    toggleableElements = toggleableElements.split('#')
    const currentShown = toggleableElements[0]
    const toHide = toggleableElements[1].split('|')
    document.getElementById(currentShown).hidden = false
    toHide.forEach((id) => {
      this.close({ params: { id } })
    })
  }
}
