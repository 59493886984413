import { Controller } from '@hotwired/stimulus'
import { templateConverter } from '../lib/template_converter'

export default class extends Controller {
  static targets = ['duplicable', 'container', 'addButton']

  create(event) {
    event.preventDefault()

    let node = null

    if (event.params && event.params.manageArray) {
      const convertedDuplicableTargetHTML = templateConverter(this.duplicableTarget.innerHTML,
        {
          array_index: Math.random().toString(36)
        })

      const placeholder = document.createElement('div')
      placeholder.innerHTML = convertedDuplicableTargetHTML
      node = placeholder.firstElementChild
    } else {
      node = this.duplicableTarget.content.cloneNode(true)
    }

    this.containerTarget.insertBefore(node, this.addButtonTarget)
  }
}
