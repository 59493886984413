import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['partform', 'link']
  static values = { index: { type: Number, default: 0 } }

  initialize() {
    this.showCurrentPart()
  }

  partformTargetConnected() {
    this.showCurrentPart()
  }

  step(event) {
    event.preventDefault()

    this.indexValue = event.params.index
    this.showCurrentPart()
  }

  showCurrentPart() {
    this.linkTargets.forEach((element, index) => {
      if (index === this.indexValue) {
        element.classList.add('active')
      } else {
        element.classList.remove('active')
      }
    })
    this.partformTargets.forEach((element, index) => {
      element.hidden = index !== this.indexValue
    })
  }
}
