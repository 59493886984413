import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  disable() {
    this.element.disabled = true
    this.element.classList.add('cursor-wait')
  }

  enable() {
    this.element.disabled = false
    this.element.classList.remove('cursor-wait')
  }
}
