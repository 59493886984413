import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['child', 'limiter']
  static values = {
    gapBetweenParentAndChild: { type: Number, default: 0 }
  }

  setChildPosition() {
    this.childTargets.forEach(function (e) {
      if (!e.hidden) {
        const parentPosition = e.parentElement.getBoundingClientRect()
        const tmpY = parentPosition.y + this.gapBetweenParentAndChildValue
        const limiterPosition = this.limiterTarget.getBoundingClientRect()

        if (limiterPosition.y < tmpY && limiterPosition.bottom > tmpY) {
          e.style.top = tmpY + 'px'
        } else {
          e.hidden = true
        }
      }
    }.bind(this))
  }
}
