import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['urlTemplateContainer']
  static values = { itemHtmlSelector: String }

  connect() {
    document.addEventListener('autocomplete.change', this.updateUrl.bind(this))
  }

  disconnect() {
    document.removeEventListener('autocomplete.change', this.updateUrl.bind(this))
  }

  urlTemplateContainerTargetConnected(target) {
    const url = target.dataset.urlBaseValue
    const hasAlreadyAParameter = url.includes('?')
    const selectedIdsWithoutIdsParams = this._selectedIdsForParams(hasAlreadyAParameter)

    target.dataset.autocompleteUrlValue = `${url}${selectedIdsWithoutIdsParams}`
  }

  urlTemplateContainerTargetDisconnected() {
    this.updateUrl()
  }

  updateUrl() {
    document.querySelectorAll('[data-autocomplete-url-value]').forEach(function (element) {
      const url = element.dataset.urlBaseValue
      const hasAlreadyAParameter = url.includes('?')
      const selectedIdsWithoutIdsParams = this._selectedIdsForParams(hasAlreadyAParameter)
      element.dataset.autocompleteUrlValue = `${url}${selectedIdsWithoutIdsParams}`
    }.bind(this))
  }

  _selectedIdsForParams(hasAlreadyAParameter) {
    const charForParams = hasAlreadyAParameter ? '&' : '?'
    const alreadyPickIds = document.querySelectorAll(this.itemHtmlSelectorValue)
    const ids = [...alreadyPickIds].map(i => i.value).filter(i => i)
    return `${charForParams}without_ids[]=` + ids.join('&without_ids[]=')
  }
}
