import Bugsnag from '@bugsnag/js'

if (['demo', 'integration', 'production'].includes(process.env.RAILS_ENV)) {
  Bugsnag.start(
    {
      apiKey: 'c200701f2e93887964c23271efcba2cf',
      releaseStage: process.env.RAILS_ENV
    }
  )
}
