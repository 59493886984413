import { Controller } from '@hotwired/stimulus'
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = ['hideable', 'wipeable']
  static values = {
    availableForTrigger: { type: Array, default: [] },
    init: { type: Boolean, default: true },
    clearOnHide: { type: Boolean, default: false },
    disableOnHide: { type: Boolean, default: false }
  }

  connect() {
    useClickOutside(this)
    this.initValue ? this.show() : this.hide()
  }

  hide() {
    if (!this.hideableTarget.hidden) {
      document.activeElement.blur()
    }
    this.hideableTarget.hidden = true

    if (this.clearOnHideValue) {
      this.wipeableTarget.value = ''
    }

    if (this.disableOnHideValue) {
      this.wipeableTarget.disabled = true
    }
  }

  show() {
    this.hideableTarget.hidden = false

    if (this.disableOnHideValue) {
      this.wipeableTarget.disabled = false
    }
  }

  toggle() {
    this.hideableTarget.hidden ? this.show() : this.hide()
  }

  toggleForSelect(event) {
    // -1 is when no option is selected
    const index = event.currentTarget.selectedIndex === -1 ? 0 : event.currentTarget.selectedIndex
    this.availableForTriggerValue[index] ? this.show() : this.hide()

    if (this.hasWipeableTarget) {
      this.wipeableTarget.placeholder = ''
    }
  }

  toggleForToggle(event) {
    event.currentTarget.parentElement.dataset.toggleActiveValue === 'true' ? this.show() : this.hide()
  }
}
