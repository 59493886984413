import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['hiddenInputToClear']
  static values = { old: String }

  clearHiddenInput() {
    if (this.hiddenInputToClearTarget.value === this.oldValue) return

    this.oldValue = this.hiddenInputToClearTarget.value
    this.hiddenInputToClearTarget.dispatchEvent(new Event('input'))
  }
}
