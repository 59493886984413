import Dropzone from 'dropzone'
import { Controller } from '@hotwired/stimulus'
import { DirectUpload } from '@rails/activestorage'

Dropzone.autoDiscover = false

export default class extends Controller {
  static targets = [
    'droparea',
    'filename',
    'filesize',
    'input',
    'progress',
    'messageZone',
    'buttonZone',
    'currentFileZone'
  ]

  initialize() {
    this.usedBorderColors = ['otherRed', 'tGrey', 'tBorder']
    this.hiddenField = null
  }

  connect() {
    this.setInitialState()
    /* eslint-disable no-new */
    new Dropzone(this.dropareaTarget, {
      addedfile: (file) => { this.verifyAccept(file) },
      url: '#',
      autoProcessQueue: false
    })
    /* eslint-enable no-new */
  }

  handleInputChange() {
    Array.from(this.inputTarget.files).forEach((file) => {
      this.verifyAccept(file)
    })
  }

  markForDeletion() {
    this.currentFileZoneTarget.getElementsByClassName('actions')[0].hidden = true
    this.currentFileZoneTarget.getElementsByClassName('deleted-text')[0].hidden = false

    if (this.fileToSend === true) { return }

    this.deletedField = document.createElement('input')
    this.deletedField.setAttribute('type', 'hidden')
    this.deletedField.setAttribute('value', 'marked_for_deletion')
    this.deletedField.name = this.inputTarget.name
    this.inputTarget.after(this.deletedField)

    this.alreadySetForDeletion = true
  }

  verifyAccept(file) {
    const maxSize = this.inputTarget.getAttribute('max_size')
    if (maxSize && file.size > maxSize) {
      this.setFileSizeErrorState()
    } else if (Dropzone.isValidFile(file, this.inputTarget.accept)) {
      if (this.inputTarget.dataset.directUploadUrl) {
        this.uploadFile(file)
      } else {
        this.setFinalInfo(file)
        this.setFinishState(false)
      }
    } else {
      this.setFileTypeErrorState()
    }
  }

  reset(event) {
    event.preventDefault()
    this.inputTarget.value = null
    if (this.hiddenField) {
      this.element.removeChild(this.hiddenField)
      this.hiddenField = null
    }

    this.fileToSend = false

    if (this.alreadySetForDeletion) { this.markForDeletion() }

    this.setInitialState()
  }

  uploadFile(file) {
    const upload = new DirectUpload(
      file,
      this.inputTarget.dataset.directUploadUrl,
      this // callback directUploadWillStoreFileWithXHR(request)
    )
    this.setUploadState()
    this.setFinalInfo(file)
    upload.create((error, blob) => {
      if (error) {
        this.setErrorState()
      } else {
        this.setFinishState()
        this.createHiddenBlobInput(blob)
      }
    })
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener('progress', (event) => {
      this.progressUpdate(event)
    })
  }

  progressUpdate(event) {
    const progress = Math.round((event.loaded / event.total) * 100)
    this.setProgression(progress)
  }

  createHiddenBlobInput(blob) {
    this.hiddenField = document.createElement('input')
    this.hiddenField.setAttribute('type', 'hidden')
    this.hiddenField.setAttribute('value', blob.signed_id)
    this.hiddenField.name = this.inputTarget.name
    this.element.appendChild(this.hiddenField)
  }

  setFinalInfo(file) {
    this.filenameTarget.innerHTML = file.name
    this.filesizeTarget.innerHTML = this.fbytesToSize(file.size)
    this.fileToSend = true
  }

  setErrorState() {
    this.enableClick()
    this.setState('dropzone-error', 'dropzone-initial')
    this.setBorderSolid()
    this.dispatch('upload:finish')
    this.setBorderColor('otherRed')
  }

  setFileTypeErrorState() {
    this.enableClick()
    this.setState('dropzone-error-file', 'dropzone-initial')
    this.setBorderSolid()
    this.setBorderColor('otherRed')
  }

  setFileSizeErrorState() {
    this.enableClick()
    this.setState('dropzone-error-file-size', 'dropzone-initial')
    this.setBorderSolid()
    this.setBorderColor('otherRed')
  }

  setInitialState() {
    this.setProgression(0)
    this.enableInput()
    this.enableClick()
    this.setState('dropzone-initial')
    this.setBorderDashed()
    this.setBorderColor('tGrey')
  }

  setUploadState() {
    this.disableClick()
    this.dispatch('upload:start')
    this.setState('dropzone-upload')
  }

  setFinishState(disableInput = true) {
    if (disableInput) { this.disableInput() }
    if (this.deletedField) { this.deletedField.remove() }
    this.disableClick()
    this.setState('dropzone-finish')
    this.dispatch('upload:finish')
    this.setBorderSolid()
    this.setBorderColor('tBorder')
  }

  setState(messageState, buttonState = messageState) {
    this.setMessageZone(messageState)
    this.setButtonZone(buttonState)
  }

  setMessageZone(selector) {
    const messages = this.messageZoneTarget.children
    Array.from(messages).forEach((message) => {
      message.hidden = !message.classList.contains(selector)
    })
  }

  setButtonZone(selector) {
    const buttons = this.buttonZoneTarget.children
    Array.from(buttons).forEach((button) => {
      button.hidden = !button.classList.contains(selector)
      if (button.dataset.action) {
        button.classList.add('pointer-events-auto')
      }
    })
  }

  setProgression(progress) {
    this.messageZoneTarget.querySelector('.progression').style.width = `${progress}%`
  }

  disableClick() {
    this.dropareaTarget.classList.add('pointer-events-none')
  }

  enableClick() {
    this.dropareaTarget.classList.remove('pointer-events-none')
  }

  disableInput() {
    this.inputTarget.disabled = true
  }

  enableInput() {
    this.inputTarget.disabled = false
  }

  setBorderSolid() {
    this.dropareaTarget.classList.add('border-solid')
    this.dropareaTarget.classList.remove('border-dashed')
  }

  setBorderDashed() {
    this.dropareaTarget.classList.add('border-dashed')
    this.dropareaTarget.classList.remove('border-solid')
  }

  setBorderColor(color) {
    this.usedBorderColors.forEach((borderColor) => {
      this.dropareaTarget.classList.remove(`border-${borderColor}`)
    })
    this.dropareaTarget.classList.add(`border-${color}`)
  }

  fbytesToSize(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (bytes === 0) return '0 Byte'
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
  }
}
