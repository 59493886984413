import { Controller } from '@hotwired/stimulus'
import { get, post } from '@rails/request.js'

export default class extends Controller {
  static targets = ['params']
  static values = {
    url: String,
    initOnConnect: Boolean,
    method: { type: String, default: 'get' }
  }

  connect() {
    if (this.initOnConnectValue) {
      this.fetchUrl()
    }
  }

  fetchUrl() {
    switch (this.methodValue) {
      case 'get':
        get(`${this.urlValue}?${this.params}`)
        break
      case 'post':
        post(`${this.urlValue}?${this.params}`, {
          responseKind: 'turbo-stream'
        })
        break
    }
  }

  get params() {
    return this.paramsTargets.map((t) => `${t.name}=${encodeURIComponent(t.value)}`).join('&')
  }
}
