import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "selectButton",
    "selectDropdown",
    "selectDropdownOption",
    "selectInput",
    "selectLabel"
  ]
  static values = { selectedOptionIndex: { type: Number, default: 0 } }

  initialize () {
    this._handleSelectOutsideClick = this._handleSelectOutsideClick.bind(this)
    this._handleKeydown = this._handleKeydown.bind(this)
  }

  increaseSelectedOptionIndex() {
    this.selectedOptionIndexValue++
  }

  decreaseSelectedOptionIndex() {
    this.selectedOptionIndexValue--
  }

  selectedOptionIndexValueChanged() {
    this.selectDropdownOptionTargets.forEach((option, index) => {
      option.classList.add('focus-by-keyboard')
      if (index === this.absoluteSelectedOptionIndex()) {
        option.classList.add('focus')
      } else {
        option.classList.remove('focus')
      }
    })
  }

  addControllerListener() {
    document.addEventListener('click', this._handleSelectOutsideClick)
    document.addEventListener('keydown', this._handleKeydown)
  }

  removeControllerListener() {
    document.removeEventListener('click', this._handleSelectOutsideClick)
    document.removeEventListener('keydown', this._handleKeydown)
  }

  toggleListVisibility() {
    this.selectDropdownTarget.hidden = !(this.selectDropdownTarget.hidden)
    if (!this.selectDropdownTarget.hidden) {
      this.addControllerListener()
    } else {
      this.removeControllerListener()
    }
  }

  clearFocusClass() {
    this.selectDropdownOptionTargets.forEach((option) => {
      option.classList.remove('focus')
      option.classList.remove('focus-by-keyboard')
    })
  }

  absoluteSelectedOptionIndex () {
    return ((this.selectedOptionIndexValue % this.selectDropdownOptionTargets.length)
      + this.selectDropdownOptionTargets.length) % this.selectDropdownOptionTargets.length
  }

  _handleSelectOutsideClick(event) {
    if (this.selectButtonTarget.contains(event.target) === false) {
      this.selectDropdownTarget.hidden = true
      this.removeControllerListener()
    }
  }

  _handleKeydown(event) {
    if (event.key === 'ArrowUp') {
      this.decreaseSelectedOptionIndex()
    } else if (event.key === 'ArrowDown') {
      this.increaseSelectedOptionIndex()
    } else if (event.key === 'Enter') {
      event.preventDefault()
      this.setHtmlSelectValue()
    } else if (['Escape', 'Tab'].includes(event.key)) {
      this.toggleListVisibility()
    } else if (event.key === ' ') {
      event.preventDefault()
    }
  }

  setClickValue(event) {
    this.selectDropdownOptionTargets.forEach((option, index) => {
      if (event.currentTarget === option) {
        this.selectedOptionIndexValue = index
      }
    })
    this.setHtmlSelectValue()
  }

  setHtmlSelectValue() {
    this.selectDropdownOptionTargets.forEach((element, index) => {
      element.classList.remove('selected')
      if (index === this.absoluteSelectedOptionIndex()) {
        element.classList.add('selected')
        this.selectLabelTarget.innerHTML = element.dataset.selectSelectedLabelParam
        this.selectInputTarget.value = element.dataset.selectSelectedValueParam
      }
    })
    this.selectInputTarget.dispatchEvent(new Event('change'))
    this.toggleListVisibility()
  }
}
