import { Controller } from '@hotwired/stimulus'
import { useHover } from 'stimulus-use'

export default class extends Controller {
  static values = {
    triggeringElementId: String,
    triggeringAttribute: String,
    position: String,
    padding: Number,
    width: Number,
    align: String
  }

  initialize() {
    this.tooltipContent = document.getElementById('tooltip-content')
  }

  connect() {
    if (this.hasTriggeringElementIdValue) {
      const matchingNode = document.getElementById(this.triggeringElementIdValue)
      useHover(this, { element: matchingNode })
    } else {
      useHover(this, { element: this.element.parentNode })
    }
  }

  mouseEnter(event) {
    if (this.hasTriggeringAttributeValue && event.target.getAttribute(this.triggeringAttributeValue) === null) {
      return
    }
    this.tooltipContent.innerHTML = this.element.getElementsByClassName('tooltip-content')[0].innerHTML
    this.tooltipContent.classList.remove('hidden')
    this.tooltipContent.style.width = `${this.widthValue}px`
    this.elementPosition = event.target.getBoundingClientRect()
    this.tooltipPosition = this.tooltipContent.getBoundingClientRect()
    this.setPosition()
  }

  mouseLeave() {
    this.tooltipContent.classList.add('hidden')
  }

  setPosition() {
    switch (this.positionValue) {
      case 'top':
        this.setPositionTop()
        break
      case 'left':
        this.setPositionLeft()
        break
      case 'right':
        this.setPositionRight()
        break
      case 'bottom':
        this.setPositionBottom()
        break
      default:
        this.setPositionTop()
    }
  }

  setPositionTop() {
    this.tooltipContent.style.top = `${this.elementPosition.top - this.tooltipPosition.height - this.paddingValue}px`
    this.tooltipContent.style.left = `${this.elementPosition.left}px`
  }

  setPositionLeft() {
    if (this.alignValue === 'bottom') {
      const topPx = this.elementPosition.top - this.tooltipPosition.height + this.elementPosition.height
      this.tooltipContent.style.top = `${topPx}px`
    } else {
      this.tooltipContent.style.top = `${this.elementPosition.top}px`
    }
    this.tooltipContent.style.left = `${this.elementPosition.left - this.tooltipPosition.width - this.paddingValue}px`
  }

  setPositionRight() {
    if (this.alignValue === 'bottom') {
      const topPx = this.elementPosition.top - this.tooltipPosition.height + this.elementPosition.height
      this.tooltipContent.style.top = `${topPx}px`
    } else {
      this.tooltipContent.style.top = `${this.elementPosition.top}px`
    }
    this.tooltipContent.style.left = `${this.elementPosition.right + this.paddingValue}px`
  }

  setPositionBottom() {
    this.tooltipContent.style.top = `${this.elementPosition.bottom + this.paddingValue}px`
    this.tooltipContent.style.left = `${this.elementPosition.left}px`
  }
}
