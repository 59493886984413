import '@hotwired/turbo-rails'

window.addEventListener('turbo:load', function () {
  if (typeof(analytics) !== 'undefined') {
    analytics.page()
  }

  const HW_config = {
    selector: '#menu-main-new_things > a',
    account: 'eJ5kgx',
    trigger: '#menu-main-new_things'
  }

  if (window.Headway) {
    window.Headway.init(HW_config)
  }
})
