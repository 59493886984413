import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['content', 'toggle']
  static values = {
    container: String
  }

  toggle() {
    this.contentTarget.classList.toggle('hidden')
    for (const icon of this.toggleTarget.children) {
      icon.classList.toggle('hidden')
    }
  }
}
