// templateConverter.js
/**
 * Returns a html with replaced {{vars}} into templateHTML by their values set in variablesObj.
 */
export function templateConverter(templateHTML, variablesObj) {
  const regex = /{{([^}]+)}}/g
  const result = []
  let arr

  while (arr = regex.exec(templateHTML)) {
    if (arr.length > 1) {
      const variableName = arr[1]
      result.push(variableName)
    }
  }

  let finalTemplateStr = templateHTML
  result.forEach(variableName => {
    const value = variablesObj[variableName] || ''
    finalTemplateStr = finalTemplateStr.replace(`{{${variableName}}}`, value)
  })
  return finalTemplateStr
}
