import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static values = {
    urlError: String
  }

  openIntercomMessenger(event) {
    event.preventDefault()
    if (window.Intercom) {
      const message = (typeof event.params.message === 'undefined') ? '' : event.params.message
      window.Intercom('showNewMessage', message)
    } else {
      get(`${this.urlErrorValue}&message=adblock_enabled&type=error`)
    }
  }
}
