import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
  static values = { followUrl: String }

  goto() {
    const followURL = this.followUrlValue
    setTimeout(function(){
      window.location.href = followURL
    }, 2000);
  }
}
