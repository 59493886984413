import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['radio']

  select(event) {
    if (!event.currentTarget.classList.contains('disabled')) {
      this.radioTargets.forEach(radio => radio.classList.remove('selected'))
      event.currentTarget.classList.add('selected')
    }
  }
}
