import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["reloadableTurboFrame"];

  connect() {
    document.addEventListener("visibilitychange", async () => {
      if (!document.hidden) {
        this.reloadableTurboFrameTargets.forEach((reloadableTurboFrame) => {
          // eslint-disable-next-line no-self-assign
          reloadableTurboFrame.src = reloadableTurboFrame.src;
        });
      }
    });
  }
}
