import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  connect() {
    this.hidden = this.inputTarget.type === 'password'
    this.class = 'hidden'
  }

  toggle(event) {
    event.preventDefault()

    this.inputTarget.type = this.hidden ? 'text' : 'password'
    this.hidden = !this.hidden
  }
}
