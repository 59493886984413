import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "toggleButton",
                     "toggleHtmlElement",
                     "toggleInput" ]
  static values = { active: Boolean }

  switchState() {
    this.activeValue = !this.activeValue
  }

  activeValueChanged() {
    this.toggleInputTarget.checked = this.activeValue;
    if (this.activeValue) {
      this.toggleHtmlElementTargets.forEach(function(htmlElement){
        htmlElement.classList.add('enabled')
      });
    } else {
      this.toggleHtmlElementTargets.forEach(function(htmlElement){
        htmlElement.classList.remove('enabled')
      });
    }

    this.toggleInputTarget.dispatchEvent(new Event('change'))
  }
}