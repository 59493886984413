import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['operator']

  operatorTargetConnected(target) {
    this._disableValue(target)
  }

  updateValue(e) {
    this._disableValue(e.target)
  }

  _disableValue(target) {
    const valueElements = target.closest('.operators-and-value').children.item(1).children
    const noValueNeeed = target.value === 'undefined'

    for (const item of valueElements) {
      if (noValueNeeed) {
        item.disabled = true
        item.value = ''
        item.classList.add('bg-bIvoryLight')
      } else {
        item.disabled = false
        item.classList.remove('bg-bIvoryLight')
      }
    }
  }
}
