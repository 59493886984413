import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['removable', 'markForDestroy', 'markForDestroyContainer']

  remove() {
    this.removableTarget.remove()
  }

  hideAndMarkForDestroy() {
    this.removableTarget.hidden = true
    this.markForDestroyTarget.value = true
    this.markForDestroyContainerTarget.dataset.markedForDestroy = true
  }
}
