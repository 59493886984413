import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['observable', 'dynamicFieldRendered']
  waitingForSubmit = false

  connect() {
    document
      .querySelector('#filters-save-button')
      .addEventListener('click', this.trySubmission.bind(this))
    document
      .querySelector('#dialog-confirm-filters #dialog-confirm-filters-confirm-button')
      .addEventListener('click', this.__applyFilters.bind(this))
  }

  observableTargetConnected(target) {
    if (target.tagName !== 'SPAN') { // Do not dirty form if trash span button connects
      this.dirty()
      target.onchange = function (t) {
        if (t.currentTarget.dataset.defaultValue !== t.currentTarget.value) {
          this.dirty()
        } else {
          this.clean()
        }
      }.bind(this)
    } else {
      // dirty form when a filter is deleted, on click on trash span button
      target.onclick = function (_t) { this.dirty() }.bind(this)
    }
  }

  // This target is rendered after filters are applied
  dynamicFieldRenderedTargetConnected() {
    this.clean()
    if (this.waitingForSubmit) {
      document.getElementById('devices_group_form').requestSubmit()
    }
  }

  trySubmission(event) {
    if (this.__isInDirtyState()) {
      event.preventDefault()
      this.waitingForSubmit = true
      this.__showModal()
    } else {
      this.__setChanged('false')
    }
  }

  dirty() {
    this.__setChanged('true')
  }

  clean() {
    this.__setChanged('false')
  }

  __setChanged(changed) {
    this.data.set('changed', changed)
  }

  __isInDirtyState() {
    return this.data.get('changed') === 'true'
  }

  __applyFilters() {
    this.waitingForSubmit = true
    document.querySelector('[form="form_filters"]').click()
  }

  __showModal() {
    document.getElementById('dialog-confirm-filters').hidden = false
  }

  __hideModal() {
    document.getElementById('dialog-confirm-filters').hidden = true
    this.waitingForSubmit = false
  }
}
