import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'counter']
  static values = {
    max: { type: Number, default: 0 }
  }

  initialize() {
    this.updateCount = this.updateCount.bind(this)
  }

  connect() {
    this.updateCount()
    this.inputTarget.addEventListener('input', this.updateCount)
  }

  disconnect() {
    this.inputTarget.removeEventListener('input', this.updateCount)
  }

  updateCount() {
    if (this.maxValue > 1) {
      this.counterTarget.innerHTML = (this.maxValue - this.count).toString()
    } else {
      this.counterTarget.innerHTML = this.count.toString()
    }
  }

  get count() {
    return this.inputTarget.value.length
  }
}
