import { Controller } from '@hotwired/stimulus'
import { post } from '@rails/request.js'

export default class extends Controller {
  static values = {
    webToken: String,
    packageName: String,
    configId: String,
    type: String,
    extraDpc: String,
    addAppType: String,
    addAppPath: String,
    userLocale: String
  }

  connect() {
    if (!window.gapi) {
      const script = document.createElement('script')
      script.src = 'https://apis.google.com/js/api.js'
      script.type = 'text/javascript'
      document.body.parentNode.appendChild(script)

      this._initIframe()
    } else {
      this._initIframe()
    }
  }

  close() {
    window.location.reload()
  }

  _initIframe() {
    const interval = setInterval(function () {
      if (!window.gapi) return
      clearInterval(interval)

      const iframeContainer = document.getElementById('google-iframe-content')
      const iframeContainerHeight = document.getElementsByTagName('body')[0].offsetHeight - 100
      const iframeContainerWidth = document.getElementById('google-iframe-container').offsetWidth - 20

      let url = ''
      if (this.typeValue === 'oem') {
        url = `https://play.google.com/managed/mcm?token=${this.webTokenValue}&packageName=${this.packageNameValue}&locale=${this.userLocaleValue}`
      } else if (this.typeValue === 'add_app') {
        url = `https://play.google.com/work/embedded/search?token=${this.webTokenValue}&` +
              `locale=${this.userLocaleValue}&iframehomepage=${this.addAppTypeValue}&mode=SELECT`
      } else {
        url = `https://enterprise.google.com/android/zero-touch/embedded/companyhome?token=${this.webTokenValue}` +
              `&dpcExtras=${this.extraDpcValue}&locale=${this.userLocaleValue}&dpcId=com.google.android.apps.work.clouddpc`
      }

      if (this.configIdValue) {
        url = `${url}&mcmId=${this.configIdValue}`
      }

      window.gapi.load('gapi.iframes', function () {
        const options = {
          url: url,
          where: iframeContainer,
          attributes: { height: `${iframeContainerHeight}px`, width: `${iframeContainerWidth}px`, style: { overflow: 'auto' }, scrolling: 'yes' }
        }

        const iframe = window.gapi.iframes.getContext().openChild(options)
        if (this.typeValue === 'oem') {
          iframe.register('onconfigupdated', function (event) {
            document.getElementById('devices_configurations_android_managed_configuration_oem_config_id').value = event.mcmId
            document.getElementById('devices_configurations_android_managed_configuration_force_push_policy').value = true
            document.getElementById('devices_configuration_form').requestSubmit()
          }, gapi.iframes.CROSS_ORIGIN_IFRAMES_FILTER)
        }

        if (this.typeValue === 'add_app') {
          const alreadySelectedPackageNames = []
          const addAppPath = this.addAppPathValue

          iframe.register('onproductselect', function (event) {
            const appToAdd = event.packageName
            if (alreadySelectedPackageNames.includes(appToAdd) === true) {
              return
            }

            alreadySelectedPackageNames.push(appToAdd)
            post(`${addAppPath}?package_name=${appToAdd}`)
          }, gapi.iframes.CROSS_ORIGIN_IFRAMES_FILTER)
        }
      }.bind(this))
    }.bind(this), 10)
  }
}
