import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "closableOnOutsideClick" ]
  static classes = [ "visible" ]

  connect() {
    document.addEventListener('click', this._handleOutsideClick.bind(this))
  }

  disconnect() {
    document.removeEventListener('click', this._handleOutsideClick)
  }

  _handleOutsideClick(event) {
    this.closableOnOutsideClickTargets.forEach((target) => {
      if (target.contains(event.target) === false) {
        target.classList.remove(...this.visibleClasses)
      }
    })
  }
}
