import { Controller } from '@hotwired/stimulus'
import { templateConverter } from '../lib/template_converter'

export default class extends Controller {
  static targets = [
    'atLeastOne',
    'actionButton',
    'onlyOne',
    'exceptedOne',
    'exceptedId',
    'toggle',
    'toggleAll',
    'selectedCount',
    'actionsDisplay',
    'paginationDisplay',
    'templateContainer',
    'template'
  ]

  static values = {
    except: { type: Number, default: -1 },
    selectedSingleLabel: { type: String, default: '' },
    selectedMultiLabel: { type: String, default: '' },
    dialogIds: { type: Array, default: [] }
  }

  initialize() {
    this.changeAction = this.changeAction.bind(this)
    this.changeAction(new Event('input'))
  }

  changeHiddenField() {
    this.actionButtonTargets.forEach((button) => {
      const form = document.getElementById(button.dataset.tableActionForm)
      form.querySelectorAll('input[name="ids[]"]').forEach(e => e.remove())
      this.checkedIds.forEach((id) => {
        const input = document.createElement('input')
        input.type = 'hidden'
        input.name = 'ids[]'
        input.value = id
        form.appendChild(input)
      })
    })
  }

  connect() {
    this.toggleAllTarget.addEventListener('change', this.changeAction)
    this.toggleTargets.forEach(checkbox => checkbox.addEventListener('change', this.changeAction))
  }

  disconnect() {
    this.toggleAllTarget.removeEventListener('change', this.changeAction)
    this.toggleTargets.forEach(checkbox => checkbox.removeEventListener('change', this.changeAction))
  }

  changeAction(event) {
    event.preventDefault()

    this.changeHiddenField()

    this.setSelectedClass()

    if (this.hasSelectedCountTarget) {
      const totalCount = this.checked.length.toString()
      if (this.checked.length > 1) {
        this.selectedCountTarget.innerHTML = `${totalCount} ${this.selectedMultiLabelValue}`
        if (this.dialogIdsValue.length > 0) {
          this.toggleDialogTexts(true)
        }
      } else {
        this.selectedCountTarget.innerHTML = `${totalCount} ${this.selectedSingleLabelValue}`
        if (this.dialogIdsValue.length > 0) {
          this.toggleDialogTexts(false)
        }
      }
    }

    if (this.checked.length === 0) {
      this.onlyOneTargets.concat(this.atLeastOneTargets).forEach((button) => {
        button.disabled = true
      })
      this.displayActions(false)
    } else if (this.checked.length === 1) {
      if (this.hasTemplateTarget && this.hasTemplateContainerTarget) {
        const checkbox = this.toggleTargets.find(checkbox => checkbox.checked)
        this.changeTemplateContainer(checkbox.dataset.templateParams)
      }
      this.onlyOneTargets.concat(this.atLeastOneTargets).forEach((button) => {
        button.disabled = !!(this.exceptedIdTargets.includes(button) && this.unwantedIds(button))
      })
      this.handleExceptOneAction(this.toggleTargets.length === this.checked.length)
      this.displayActions(true)
    } else if (this.checked.length >= 1) {
      this.onlyOneTargets.forEach((button) => {
        button.disabled = true
      })
      this.handleExceptOneAction(this.toggleTargets.length === this.checked.length)
      this.atLeastOneTargets.forEach((button) => {
        button.disabled = !!(this.exceptedIdTargets.includes(button) && this.unwantedIds(button))
      })
      this.displayActions(true)
    }
  }

  changeTemplateContainer(templateParams) {
    this.templateContainerTarget.innerHTML = templateConverter(this.templateTarget.innerHTML, JSON.parse(templateParams))
  }

  toggleDialogTexts(isMulti) {
    this.dialogIdsValue.forEach((id) => {
      const multiElements = document.getElementById(id).getElementsByClassName('table-action-multi')
      for (const element of multiElements) {
        this.handleTemplate(element)
        element.hidden = !isMulti
      }
      const soloElements = document.getElementById(id).getElementsByClassName('table-action-solo')
      for (const element of soloElements) {
        this.handleTemplate(element)
        element.hidden = isMulti
      }
    })
  }

  unwantedIds(button) {
    const exceptIds = [this.exceptValue.toString()]
    if (button.dataset.additionalExceptIds) {
      exceptIds.push(button.dataset.additionalExceptIds.split(','))
    }

    return this.checkedIds.some(id => exceptIds.flat().includes(id))
  }

  setSelectedClass() {
    this.checked.forEach((input) => {
      input.closest('tr').classList.add('selected')
    })
    this.notChecked.forEach((input) => {
      input.closest('tr').classList.remove('selected')
    })
  }

  get checkedIds() {
    return this.checked.map(o => o.value)
  }

  get checked() {
    return this.toggleTargets.filter(checkbox => checkbox.checked)
  }

  get notChecked() {
    return this.toggleTargets.filter(checkbox => !checkbox.checked)
  }

  handleTemplate(element) {
    const tag = element.tagName
    if (element.textContent.includes('{{total}}')) {
      const template = document.createElement('template')
      template.classList.add(`template-table-action-multi-${tag}`)
      template.innerHTML = element.innerHTML
      element.parentNode.appendChild(template)
    }

    /*
    * On the first execution of the function, the element text is replaced with the totalCount
    * We need to retrieve the template in the parent node, in order to replace {{total}} on each check in
    * table action checkboxes
    */
    const template = element.parentNode.querySelector(`.template-table-action-multi-${tag}`)
    if (template !== null && element.classList.contains('table-action-multi')) {
      const totalCount = this.checked.length.toString()
      element.innerHTML = templateConverter(template.innerHTML, { total: totalCount })
    }
  }

  displayActions(visible) {
    if (this.hasActionsDisplayTarget) {
      this.actionsDisplayTarget.hidden = !visible
      if (this.hasPaginationDisplayTarget) {
        this.paginationDisplayTarget.hidden = visible
      }
    }
  }

  handleExceptOneAction(disable) {
    if (disable) {
      this.exceptedOneTargets.forEach((button) => {
        button.disabled = true
      })
    } else {
      this.exceptedOneTargets.forEach((button) => {
        button.disabled = false
      })
    }
  }
}
