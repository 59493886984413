import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'part' ]


  toggle() {
    this.partTargets.forEach((part) => {
      part.hidden ? part.hidden = false : part.hidden = true
    })
  }
}
