// formParametersConverter.js
/**
 * Returns a string with all form input url encoded.
 */
export function formUrlEncodedParameters(formElement, withoutSearch = false, searchInputName = 'q') {
  const data = new FormData(formElement)
  let params = ''
  for (const pair of data.entries()) {
    if (pair[0] !== '_method' && pair[0] !== 'authenticity_token') {
      const removeSearchParam = (withoutSearch && pair[0] === searchInputName)
      if (!removeSearchParam) {
        params = params.concat(`&${pair[0]}=${pair[1]}`)
      }
    }
  }
  return params
}
