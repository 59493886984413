import mapboxgl from 'mapbox-gl'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    latitude: String,
    longitude: String,
    apiKey: String,
    containerId: String
  }

  connect() {
    mapboxgl.accessToken = this.apiKeyValue
  }

  displayMap() {
    const map = new mapboxgl.Map({
      container: this.containerIdValue,
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [this.longitudeValue, this.latitudeValue],
      zoom: 12
    })
    new mapboxgl.Marker().setLngLat([this.longitudeValue, this.latitudeValue]).addTo(map)
  }
}
